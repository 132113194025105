import { createApp } from 'vue'
import { ConfigProvider } from 'vant'
import 'vant/es/dialog/style'
import 'vant/es/toast/style'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import eruda from 'eruda'
import './styles/index.scss'
import 'windi.css'

/* 
记得修改iconfont.css文件中的
font-family: 'icon';
.iconfont修改为.icon
*/
import '@/assets/icons/iconfont.css'

if (process.env.NODE_ENV === 'development') {
  const el = document.createElement('div')
  document.body.appendChild(el)

  eruda.init({
    container: el,
    // tool: ['console', 'elements','']
  })
}

createApp(App).use(store).use(ConfigProvider).use(router).mount('#app')
