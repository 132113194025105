import Cookies from 'js-cookie'

const cookieTokenKey = 'accessToken'
const tokenClientId = 'tokenClientId'

export function getToken() {
  if (Cookies.get('tokenType')) {
    return Cookies.get(cookieTokenKey)
  } else {
    return false
  }
}

export function setToken(tokens: Record<string, any>) {
  Object.keys(tokens).forEach((element) => {
    Cookies.set(element, tokens[element])
  })
  Cookies.set(tokenClientId, process.env.VUE_APP_CLIENT_ID)
}

export function removeCookieToken() {
  Cookies.remove(tokenClientId)
  return Cookies.remove(cookieTokenKey)
}
