import { createStore } from 'vuex'
import user from './modules/user'
import common from './modules/common'

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    common,
    user,
  },
})
