import { Commit } from 'vuex'
import { getAccount } from '@/api/case'

export type UserInfo = {
  accountName: string
  accountNumber: string
  bankDeposit: string
  userinfoVO: {
    username: string
    realName: string
  }
}
const state = () => ({
  userInfo: null,
})

const mutations = {
  CHANGE_USERINFO(state: { userInfo: UserInfo | null }, v: UserInfo | null) {
    state.userInfo = v
  },
}

const actions = {
  async changUserInfo({ commit }: { commit: Commit }) {
    try {
      const res = await getAccount()
      if (res.code === '0') {
        commit('CHANGE_USERINFO', res.data)
      }
    } catch (error) {
      //
    }
  },
}

export default {
  state,
  mutations,
  actions,
}
