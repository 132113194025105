import service from '@/utils/request'
import qs from 'qs'
import {
  RelationShipEnum,
  IdTypeEnum,
  RepayKindEnum,
  AssociatedPersonTypeEnum,
  collectionMethodEnum,
  collectionTargetEnum,
  contactStatusEnum,
  lawsuitStageEnum,
  interestBearingStatusEnum,
} from '@/config/enums'
/**
 * DESC就是由近到远,ASC就是由远到近
 */
export interface ICaseList {
  current: number
  size: number
  collectionStatus?: 'NEW_CASE' | 'STOCK_CASE' | string // 案件催记状态 NEW_CASE-新增案件 STOCK_CASE-存量案件
  borrowerName?: string //借款人姓名
  caseOverdueDaysSort?: 'DESC' | 'ASC' // 案件逾期天数排序 DESC-逆序 ASC-顺序
  caseOverdueTotalAmountSort?: 'DESC' | 'ASC' // 案件逾期总金额排序 DESC-逆序 ASC-顺序
  caseStatusList?: Array<'ON_URGE' | 'STOP_URGE' | 'OUT_URGE'> // 案件状态 ON_URGE-在催 STOP_URGE-停催 OUT_URGE-出催
  collectionQueue?: string // 催收队列
  dispatchStatus?: string // 派件状态
  inCollectionDateSort?: 'DESC' | 'ASC' // 入催日期排序 DESC-逆序 ASC-顺序
  outCollectionDateSort?: 'DESC' | 'ASC' // 出催日期排序 DESC-逆序 ASC-顺序
}

export type CaseListRes = {
  caseNo: string
  caseStatus: 'ON_URGE' | 'STOP_URGE' | 'OUT_URGE'
  currentOverdueTotalAmount: number
  customerName: string
  outCollectionDate?: string
}

interface ICollectionResultListParams {
  collectionTarget: string
  collectionMethod: string
  isShow?: number
}

export type CollectionResultListRes = {
  collectionMethod: string
  collectionResult: string
  collectionTarget: string
  isShow?: number
  resultNo: string
}

// 催收员案件查询 -新增案件 -存量案件 -结清案件
export function getCaseByCollector(params: ICaseList): Promise<{
  code: string
  data: {
    current: number
    records: CaseListRes[]
    total: number
  }
}> {
  return service({
    url: '/loanManagement/getCaseByCollector',
    method: 'get',
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'indices' })
    },
  })
}

// /**
//  * 案件列表
//  */
// export function getCaseList(params) {
//   return service({
//     url: '/loanManagement/getCaseList',
//     method: 'get',
//     params: { ...params },
//   })
// }

export interface ICollectionRecordList {
  caseNo: string
  current: number
  size: number
}
export interface collectionList {
  accountManager: string
  collectionMethod: string
  collectionRegisterTime: typeof Date
  collectionResult: string
  collectionTarget: string
  collectionTargetName: string
  collectionTime: typeof Date
  collectionUserId: string
  collectionUserName: string
  contactStatus: string
  remindersNo: string
  resultNo: string
}
export interface CollectionRecordListRes {
  current: number
  records: collectionList[]
  total: 0
}
/**
 * 催收记录历史列表
 * @param params
 *    @param caseNo string
 *    @param current number
 *    @param size number
 */
export function getCollectionRecordList(params: ICollectionRecordList): Promise<{
  code: string
  data: CollectionRecordListRes
  msg: string
}> {
  return service({
    url: '/loanManagement/getCollectionRecordList',
    method: 'GET',
    params,
  })
}
/**
 * 案件详情
 */
export interface ICaseDetail {
  caseNo: string
}

export interface CaseDetailRes {
  caseInfoDetailVo?: {
    caseLoanBalance: number
    caseNo: string
    caseOverdueDays: number
    caseOverdueInterest: number
    caseOverduePenaltyInterest: number
    caseOverduePrincipal: number
    caseOverdueTotalAmount: number
    caseSameDayInterest: number
    caseSameDayPrincipal: number
    caseSameDayTotalAmount: number
  }
  caseLoanInfoVo?: {
    currentBalance: number
    dueDate: string
    loanAmount: number
    loanDate: string
    loanNo: string
    loanTerm: number
    loanTermUnit: 'DAY' | 'MONTH' | 'YEAR' //DAY-日、MONTH-月、YEAR-年
    maximumOverdueDays: number
    overdueDays: number
    overdueInterest: number
    overduePenaltyInterest: number
    overduePrincipal: number
    overdueRepaymentDate: string
    overdueTotalAmount: number
    repayKind: keyof typeof RepayKindEnum
    sameDayInterest: number
    sameDayPrincipal: number
    sameDayTotalAmount: number
    surplusLoanTerm: number
  }[]
  loanAssociatedPersonInfoCoBorrowerVo?: {
    associatedPersonName: string
    associatedPersonType: keyof typeof AssociatedPersonTypeEnum //CO_BORROWER-共借人 GUARANTOR-担保人
    contactAddress: string
    idNo: string
    idType: keyof typeof IdTypeEnum //B01-身份证 B04-护照 B25-港澳台通行证 B99-其他(对私) A18-统一社会信用代码
    loanNo: string
    mobileNo: string
    relationship: keyof typeof RelationShipEnum
  }[]
  loanAssociatedPersonInfoContactsVo?: {
    associatedPersonName: string
    associatedPersonType: keyof typeof AssociatedPersonTypeEnum
    contactAddress: string
    idNo: string
    idType: keyof typeof IdTypeEnum
    loanNo: string
    mobileNo: string
    relationship: keyof typeof RelationShipEnum
  }[]
  loanAssociatedPersonInfoGuarantorVo?: {
    associatedPersonName: string
    associatedPersonType: keyof typeof AssociatedPersonTypeEnum
    contactAddress: string
    idNo: string
    idType: keyof typeof IdTypeEnum
    loanNo: string
    mobileNo: string
    relationship: keyof typeof RelationShipEnum
  }[]
  overdueCustomerInfoVo?: {
    businessAddress: string
    customerName: string
    idNo: string
    idType: keyof typeof IdTypeEnum
    liveAddress: string
    mobileNo: string
  }
}
export function getCaseDetail(params: ICaseDetail): Promise<{
  code: string
  data: CaseDetailRes
  msg: string
}> {
  return service({
    url: '/loanManagement/getCaseDetail',
    method: 'get',
    params: { ...params },
  })
}
// /**
//  * 催收员变更
//  */
// export function updateCollection(params) {
//   return service({
//     url: '/loanManagement/updateCollection',
//     method: 'post',
//     data: { ...params },
//   })
// }
// /**
//  * 获取渠道信息
//  */
// export function getOneLevelChannel(params) {
//   return service({
//     url: '/loanManagement/getOneLevelChannel',
//     method: 'get',
//     params,
//   })
// }
// /**
//  * 获取二级渠道信息
//  */
// export function getSubChannel(params) {
//   return service({
//     url: '/loanManagement/getSubChannel',
//     method: 'get',
//     params,
//   })
// }
// /**
//  * 报表列表
//  */
// export function getReportList(params) {
//   return service({
//     url: '/loanManagement/reportList',
//     method: 'get',
//     params,
//   })
// }
// /**
//  * 报表列表导出
//  */
// export function reportListExport(params) {
//   return service({
//     url: '/loanManagement/reportListExport',
//     method: 'GET',
//     params,
//     responseType: 'blob',
//   })
// }
// /**
//  * 催记列表导出
//  */
// export function exportCaseInfo(params) {
//   return service({
//     url: '/loanManagement/exportCaseInfo',
//     method: 'GET',
//     params,
//     responseType: 'blob',
//   })
// }
// /**
//  * 导入催收记录
//  */
// export function importCollection(params) {
//   return service({
//     url: '/loanManagement/importCollection',
//     method: 'POST',
//     params,
//     responseType: 'blob',
//   })
// }

/**
 * 新增催收记录
 */
export function addCollectionRecord(params: any) {
  return service({
    url: '/loanManagement/addCollectionRecord',
    method: 'POST',
    data: { ...params },
  })
}

export interface ICollectionDetailParams {
  remindersNo: string
}
export interface CollectionDetailRes {
  commonInfo: {
    assistCollector: string
    caseNo: string
    collectionMethod: keyof typeof collectionMethodEnum
    collectionMethodRemark: string
    collectionRegisterTime: typeof Date
    collectionRemark: string
    collectionResult: string
    collectionTarget: keyof typeof collectionTargetEnum
    collectionTargetAddressRemark: string
    collectionTargetMobileRemark: string
    collectionTargetNameRemark: string
    collectionTargetOtherRemark: string
    collectionTargetRelation: keyof typeof RelationShipEnum
    collectionUserId: string
    collectionUserName: string
    contactRemark: string
    contactStatus: keyof typeof contactStatusEnum
    remark: string
    remindersNo: string
  }
  lawsuitInfo: {
    lawsuitCommon: {
      commitLawFirmDate: string
      competentCourt: string
      lawFirmName: string
      lawsuitStage: keyof typeof lawsuitStageEnum
      stopUrgingStatus: 'NON_STOP_URGING' | 'STOP_URGING'
    }
    lawsuitExecuteFiled: {
      executeCourt: string
      executeFiledDate: string
      executeFiledNo: string
      executeRemark: string
    }
    lawsuitExecutePrepare: {
      applyExecuteRemark: string
    }
    lawsuitExecuteResult: {
      executeFinalResult: 'EXECUTION_END' | 'FINAL_EXECUTION'
      executeReceiptDate: string
    }
    lawsuitExecuting: {
      executeAssetsType: ('DEPOSIT' | 'PROPERTY' | 'OTHER')[]
      executeAssetsValue: string
      executeRemark: string
      executeResult: 'SUCCESS' | 'FAIL'
      executionCourt: string
    }
    lawsuitFiledInfo: {
      filingDate: string
      filingNo: string
      remark: string
    }
    lawsuitHeld: {
      hearingDate: string
      remark: string
    }
    lawsuitMediate: {
      interestBearingStatus: keyof typeof interestBearingStatusEnum
      mediateDate: string
      mediateRemark: string
      mediateResult: 'REACH_AGREEMENT' | 'MEDIATION_FAILED'
      mediateType: string
      protocolEndRepaymentDate: string
      protocolFirstRepaymentDate: string
      protocolRepaymentAmount: number
      protocolRepaymentPeriod: number
      protocolRepaymentWay: string
    }
    lawsuitPrepareMaterials: {
      courtReviewResult: string
      remark: string
      supplementaryInfoDate: string
    }
    lawsuitSentence: {
      result: string
      sentenceDate: string
      sentenceRemark: string
    }
  }
  mobileVisitOutsourcing: {
    description: string
  }
  outsCollectionInformation: {
    commitOutsourcingDate: string
    outsourcingCollectionWay: string
    outsourcingOrganization: string
    outsourcingVisitAddress: string[]
    outsourcingVisitCollector: string
    outsourcingVisitTime: typeof Date
  }
  reminderFile: {
    fileId: string
    fileName: string
    fileType: 'fileId' | 'uri'
  }[]
  visitCollection: {
    visitAddress: string[]
    visitTime: typeof Date
  }
}
/**
 * 催收记录详情
 */
export function getCollectionRecordDetail(params: ICollectionDetailParams): Promise<{
  code: string
  data: CollectionDetailRes
}> {
  return service({
    url: '/loanManagement/getCollectionRecordDetail',
    method: 'GET',
    params,
  })
}
/**
 * 催收记录详情
 */
export function getCollectionResultList(params: ICollectionResultListParams): Promise<{
  code: number
  data: {
    collectionMethod: string
    collectionResult: string
    collectionTarget: string
    isShow?: number
    resultNo: string
  }[]
  msg: string
}> {
  return service({
    url: '/loanManagement/getCollectionResultList',
    method: 'GET',
    params,
  })
}
// /**
//  * 催收记录详情
//  */
// export function getCollectionList(params) {
//   return service({
//     url: '/loanManagement/collectionList',
//     method: 'GET',
//     params,
//   })
// }

interface IAddFilesParams {
  fileId: string
  fileName: string
  fileType: string
  remindersNo: string
}
/**
 * 催收记录上传关联文件
 */
export function addCollectionRecordFile(params: IAddFilesParams) {
  return service({
    url: '/loanManagement/addCollectionRecordFile',
    method: 'POST',
    data: { ...params },
  })
}
// /**
//  * 根据渠道获取产品
//  * @param {*} params
//  * @returns
//  */
// export function getProductInfo(params) {
//   return service({
//     url: '/loanManagement/getProductInfo',
//     method: 'GET',
//     params,
//   })
// }

interface GetAccountRes {
  accountName: string
  accountNumber: string
  bankDeposit: string
  smallLoanAccountName: string
  smallLoanAccountNumber: string
  smallLoanBankDeposit: string
  userinfoVO: {
    realName: string
    username: string
  }
}
/**
 * 根据渠道获取产品
 */
export function getAccount(): Promise<{
  code: string
  data: GetAccountRes
  msg: string
}> {
  return service({
    url: '/loanManagement/getAccount',
    method: 'GET',
  })
}
