/**
 * vant ConfigProvider 组件的变量
 * 切换主题配置
 */

import { Numeric } from 'vant/lib/utils'

export const themeVars: Record<string, Numeric> = {
  buttonRoundBorderRadius: '8px',
  buttonPrimaryBorderColor: '#1FB769',
  buttonPrimaryBackgroundColor: '#1FB769',
  tabsBottomBarColor: '#1FB769',
  collapseItemContentTextColor: '#606266',
  pickerConfirmActionColor: '#1FB769',
  pickerCancelActionColor: '#909399',
  tabsBottomBarHeight: '2px',
  pickerTitleFontSize: '16px',
}
