/*
 * @Descripttion: 对axios进行二次封装并添加拦截
 * @version:
 * @Author: ahao
 * @Date: 2021-10-29 12:17:33
 * @LastEditors: yancheng
 * @LastEditTime: 2022-08-12 09:09:57
 */
import axios from 'axios'
import { Dialog } from 'vant'
import md5 from 'js-md5'
import 'vant/es/notify/style'

import router from '@/router/index'

import { getToken, removeCookieToken } from './token'
import style from '@/styles/styles.module.scss'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    const token = getToken()
    if (!token) {
      router.push('/login')
    } else {
      if (config.headers !== undefined) {
        config.headers.Authorization = `bearer ${token}`
      } else {
        config.headers = {
          Authorization: `bearer ${token}`,
        }
      }
    }

    if (process.env.NODE_ENV !== 'localhost') {
      if (config.method === 'post' || config.method === 'put') {
        // const onceData = config.data
        // config.data = {}
        // config.data.data = onceData || {}
        // config.data.sign = md5(JSON.stringify(onceData))
        if (config.headers !== undefined) {
          config.headers['runthink-sign'] = md5(JSON.stringify(config.data || {}))
        } else {
          config.headers = {
            'runthink-sign': md5(JSON.stringify(config.data || {})),
          }
        }
      }
    }

    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  },
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    // console.log(response)
    const res = response.data
    if (res.code !== '0') {
      Dialog.alert({ message: res.msg, confirmButtonColor: style.defaultThemeColor })
      return Promise.reject(res.msg || 'Error')
    } else {
      return res
    }
  },
  (error) => {
    // console.log('err ' + error) // for debug

    const data = error.response.data
    Dialog.alert({ message: data.msg, confirmButtonColor: style.defaultThemeColor })
    // 未登录
    if (error.response.status === 401 || error.response.status === 403) {
      removeCookieToken()
      router.push('/login')
    }
    return Promise.reject(data)
  },
)

export default service
