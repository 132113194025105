import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/login',
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/login.vue'),
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: () => import(/* webpackChunkName: "login" */ '@/views/redirect/redirect.vue'),
  },
  {
    path: '/userInfo',
    name: 'UserInfo',
    component: () => import(/* webpackChunkName: "user" */ '@/views/userInfo/userInfo.vue'),
  },
  // 案件
  {
    path: '/case',
    name: 'Case',
    meta: {
      keepAlive: true,
      usePathKey: true,
    },
    component: () => import(/* webpackChunkName: "case" */ '@/views/case/caseList.vue'),
  },
  /* 案件详情 */
  {
    path: '/case/caseDetail',
    name: 'CaseDetail',
    meta: {
      keepAlive: true,
      usePathKey: true,
    },
    component: () => import(/* webpackChunkName: "case" */ '@/views/caseDetail/caseDetail.vue'),
  },
  /* 催记详情 */
  {
    path: '/case/reminderDetail',
    name: 'ReminderDetail',
    component: () =>
      import(
        /* webpackChunkName: "case" */ '@/views/caseDetail/components/reminderList/detail/reminderDetail.vue'
      ),
  },
  {
    path: '/case/reminderEdit',
    name: 'ReminderEdit',
    component: () =>
      import(
        /* webpackChunkName: "case" */ '@/views/caseDetail/components/reminderList/edit/edit.vue'
      ),
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "case" */ '@/views/searchCase/searchCase.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/notFound/index.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
