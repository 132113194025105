const state = () => ({
  caseNeedRefresh: false,
  caseNeedStock: false,
})

const mutations = {
  CHANGE_CASE_REFRESH(state: { caseNeedRefresh: boolean }, v: boolean) {
    state.caseNeedRefresh = v
  },
  CHANGE_CASE_STOCK(state: { caseNeedStock: boolean }, v: boolean) {
    state.caseNeedStock = v
  },
}

const actions = {}

export default {
  state,
  mutations,
  actions,
}
